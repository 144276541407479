<div class="flex overflow-hidden flex-wrap mt-4 py-12 px-4 sm:px-6 lg:px-8">
    <div class="w-full md:w-1/2 my-auto">
        <div
            [ngClass]="this.lang.LangState=='en' ? ['text-5xl md:text-7xl font-bold my-auto']:['text-5xl md:text-5xl font-bold my-auto pb-1']">
            <h1 [ngClass]="this.lang.LangState=='en' ? ['text-gray-800']:['text-gray-800 my-2']">
                {{'home.Online_Ordering' |translate}}</h1>
            <h1 class="text-red-400">{{'home.Done_Right' |translate}} </h1>
        </div>

        <p class="my-8 text-xl text-gray-500 lg:mx-auto">
            {{'home.intro' |translate}}

        </p>
    </div>
    <div class="w-full md:w-1/2">
        <img src="assets/inhouse_product.png" alt="Example of restaurant dashboard"
            class="max-w-full min-w-56 flex-shrink-0 h-auto w-auto block" />
    </div>

</div>

<!-- This example requires Tailwind CSS v2.0+ -->
<div class="py-12 my-18 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
            <h2 class="text-base font-semibold tracking-wide uppercase">
                <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
                    {{'home.Online_Ordering' |translate}}
                </span>

            </h2>
            <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {{'home.Built_For_Restaurants' |translate}}
            </p>
            <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                {{'home.intro2' |translate}}

            </p>
        </div>

        <div class="mt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <!-- Heroicon name: globe-alt -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                            </svg>
                        </div>
                    </div>
                    <div class="ml-4">
                        <dt class="text-lg leading-6 font-medium text-gray-900">
                            {{'home.Credit_Card' |translate}}
                        </dt>

                        <dd class="mt-2 text-base text-gray-500">
                            {{'home.Credit_Card_Intro' |translate}}

                        </dd>
                    </div>
                </div>

                <div class="flex">
                    <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                            <!-- Heroicon name: scale -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                            </svg>
                        </div>
                    </div>
                    <div class="ml-4">
                        <dt class="text-lg leading-6 font-medium text-gray-900">
                            {{'home.No_hidden' |translate}}
                        </dt>
                        <dd class="mt-2 text-base text-gray-500">
                            {{'home.No_hidden_Intro' |translate}}

                        </dd>
                    </div>
                </div>

                <div class="flex">
                    <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-purple-500 text-white">
                            <!-- Heroicon name: lightning-bolt -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                        </div>
                    </div>
                    <div class="ml-4">
                        <dt class="text-lg leading-6 font-medium text-gray-900">
                            {{'home.Account_Manager' |translate}}
                        </dt>
                        <dd class="mt-2 text-base text-gray-500">
                            {{'home.Account_Manager_Intro' |translate}}

                        </dd>
                    </div>
                </div>

                <div class="flex">
                    <div class="flex-shrink-0">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-green-400 text-white">
                            <!-- Heroicon name: annotation -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                            </svg>
                        </div>
                    </div>
                    <div class="ml-4">
                        <dt class="text-lg leading-6 font-medium text-gray-900">
                            {{'home.Custom_Website' |translate}}
                        </dt>
                        <dd class="mt-2 text-base text-gray-500">
                            {{'home.Custom_Website_Intro' |translate}}

                        </dd>
                    </div>
                </div>
            </dl>
        </div>
    </div>
</div>

<div class="bg-gray-100 rounded-sm px-0 py-8 md:p-8 my-12 mt-0 flex flex-wrap align-middle shadow-sm">
    <div class="w-full md:w-1/2 bg-gray-800 text-white shadow-2xl rounded-r-2xl my-8">
        <img src="https://res.cloudinary.com/inhouse-orders/image/upload/w_800,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg"
            alt="Thai House Dishes" class="rounded-r-2xl" />
        <div style="
        margin-top: -3rem; 
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
      " class="bg-gray-800 p-8 rounded-r-2xl">
            <h6 class="font-bold text-2xl py-4 pt-6">Thai House Denver</h6>
            <p class="text-lg">
                {{'home.Thai_House_Intro' |translate}}
            </p>
            <blockquote
                class="text-xl p-4 mt-4 bg-purple-300 bg-opacity-20 border-l-4 border-purple-400 italic rounded-r-lg">

                {{'home.Thai_House_saying' |translate}}
                <span class="block text-md text-right"> — Mei Li, 2020</span>

            </blockquote>
            <a href="https://thaihousedenver.com/"
                class="text-center bg-purple-400 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full block transition-all py-2 mt-2 text-lg font-bold hover:text-white hover:py-3">{{'home.Thai_House'
                |translate}}
                <svg class="h-5 w-5 inline-block align-center" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
            </a>
        </div>
    </div>
    <div class=" px-0 py-8 md:p-8 w-full md:w-1/2 my-auto">
        <p class="m-2 text-2xl pt-8 border-t-2 border-gray-300">
            {{'home.intro3' |translate}}

        </p>
        <p class="m-2 text-lg mt-4">
            {{'home.intro4' |translate}}

        </p>
        <p class="m-2 text-lg mt-4 pb-8 border-b-2 border-gray-300">
            {{'home.intro5' |translate}}

        </p>
    </div>
</div>

<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-gray-800">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            <span class="block">{{'home.Ready' |translate}}</span>
            <span class="block text-purple-400">{{'home.start' |translate}}</span>
        </h2>
        <div class="mt-8 lex lg:mt-0 lg:flex-shrink-0">
            <div class="inline-flex rounded-md shadow">
                <a [routerLink]="['/get-started']"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-white bg-gradient-to-r from-green-400 to-blue-500">
                    {{'home.Get_started' |translate}}
                </a>
            </div>
            <div class="ml-3 inline-flex rounded-md shadow">
                <a [routerLink]="['/how-it-works']"
                    class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-purple-400 bg-white hover:bg-indigo-50">
                    {{'home.Learn_more' |translate}}
                </a>
            </div>
        </div>
    </div>
</div>