import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { LoginComponent } from './login/login.component';
// import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { FaqComponent } from './faq/faq.component';
import { AccountComponent } from './account/account.component';
import { FormSubmissionComponent } from './form-submission/form-submission.component';

import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from './translate-browser';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    PricingComponent,
    GetStartedComponent,
    LoginComponent,
    HowItWorksComponent,
    FaqComponent,
    AccountComponent,
    FormSubmissionComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AngularFireModule,
    // AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    // AngularFireFunctionsModule,
    // AngularFirestoreModule,
    TransferHttpCacheModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),

  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
