<div class="flex flex-wrap py-12 px-4 sm:px-6 lg:px-8">

    <h1 class="text-3xl w-full font-bold"> {{'faq.faq' |translate}}</h1>
    <hr class="w-full my-4">
    <div class="w-full md:w-1/2 p-4">
        <p class="text-gray-900 text-sm font-bold">
            {{'faq.delivery' |translate}}
        </p>
        <p class="text-gray-500 text-sm"> {{'faq.delivery_A' |translate}}</p>
    </div>

    <div class="w-full md:w-1/2 p-4">
        <p class="text-gray-900 text-sm font-bold">
            {{'faq.processing_fees' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.processing_fees_A' |translate}} </p>
    </div>
    <div class="w-full md:w-1/2 p-4 ">
        <p class="text-gray-900 text-sm font-bold">
            {{'faq.POS' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.POS_A' |translate}}</p>
    </div>

    <div class="w-full md:w-1/2 p-4 ">
        <p class="text-gray-900 text-sm font-bold">
            {{'faq.edit' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.edit_A' |translate}} </p>
    </div>

    <div class="w-full md:w-1/2 p-4 ">
        <p class="text-gray-900 text-sm font-bold">
            {{'faq.tips' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.tips_A' |translate}} </p>
    </div>

    <div class="w-full md:w-1/2 p-4">
        <p class="text-gray-900 text-sm  font-bold">
            {{'faq.paid_SEO' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.paid_SEO_A' |translate}} </p>
    </div>
    <div class="w-full md:w-1/2 p-4">
        <p class="text-gray-900 text-sm  font-bold">
            {{'faq.opoutGoogle' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.opout_A' |translate}} </p>
    </div>
    <div class="w-full md:w-1/2 p-4">
        <p class="text-gray-900 text-sm  font-bold">
            {{'faq.contact' |translate}}
        </p>
        <p class="text-gray-500 text-sm">{{'faq.contact-A' |translate}} </p>
    </div>


</div>