import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth,) { }

  public loginWithEmail = false;
  public emailInput = '';
  public pwInput = '';
  public forgotPassword = false;


  ngOnInit(): void {



  }
  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      return this.oAuthLogin(provider);

    }
    catch (error) {
      // Handle Errors here.
      //console.log(error);
      if (error.code == 'auth/account-exists-with-different-credential') {
        //console.log(error.message);
        alert('Sign in Error: ' + error.email + ' ' + error.message);
      }
    }
  }

  async fbSignin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    try {
      this.oAuthLogin(provider);

    }
    catch (error) {
      // Handle Errors here.
      //console.log(error);
      if (error.code == 'auth/account-exists-with-different-credential') {
        //console.log(error.message);
        alert('Sign in Error: ' + error.email + ' ' + error.message);
      }
    }
  }

  private async oAuthLogin(provider) {
    try {
      await this.afAuth.signInWithPopup(provider);

    } catch (error) {
      console.log(error);
      if (error.code == 'auth/account-exists-with-different-credential') {
        //console.log(error.message);
        alert('Sign in Error: ' + error.email + '\n ' + error.message);
      }

    }
  }


  async login() {

    try {
      let result = await this.afAuth.signInWithEmailAndPassword(this.emailInput, this.pwInput)
    } catch (error) {

      alert(error)

    }

  }

  async signup() {

    try {

      await this.afAuth.createUserWithEmailAndPassword(this.emailInput, this.pwInput)

    } catch (error) {

      alert(error)

    }
  }

  async resetPw() {

    try {
      await this.afAuth.sendPasswordResetEmail('email')
    } catch (error) {
      alert(error)
    }
  }



}
