import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { LanguageService } from '../language.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public mobileMenuOpen = false;
  public accountMenuOpen = false;

  

  constructor(public afAuth: AngularFireAuth,public lang:LanguageService) {
   
   }

  ngOnInit(): void {
  }

 

}
