<div class="py-12 px-4 sm:px-6 lg:px-8">
    <h1 class="text-3xl w-full font-bold">{{'work.How_It_Works' |translate}}</h1>
    <hr class="w-full my-4">
    <ol>
        <li>
            <div class="flex my-2">
                <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-400 text-white">

                        <span class="text-xl font-bold">1</span>
                    </div>
                </div>
                <div class="ml-4">
                    <dt [ngClass]="this.lang.LangState=='en' ? ['text-lg leading-6 font-medium text-gray-900']:['text-lg leading-6 font-bold text-gray-900']">
                        {{'work.Get_Started' |translate}}
                    </dt>
                    <dd [ngClass]="this.lang.LangState=='en' ? ['text-base text-gray-500']:['text-base my-1 text-gray-500']">
                        {{'work.Get_Started_Intro' |translate}}
                        
                    </dd>
                </div>
            </div>

        </li>

       

        <li>
            <div class="flex my-2">
                <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">

                        <span class="text-xl font-bold">2</span>
                    </div>
                </div>
                <div class="ml-4">
                    <dt [ngClass]="this.lang.LangState=='en' ? ['text-lg leading-6 font-medium text-gray-900']:['text-lg leading-6 font-bold text-gray-900']">
                        {{'work.Match' |translate}}
                    </dt>
                    <dd [ngClass]="this.lang.LangState=='en' ? ['text-base text-gray-500']:['text-base my-1 text-gray-500']">
                        {{'work.Match_Intro' |translate}}
                        
                    </dd>
                </div>
            </div>

        </li>


        <li>
            <div class="flex my-2">
                <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-green-400 text-white">

                        <span class="text-xl font-bold">3</span>
                    </div>
                </div>
                <div class="ml-4">
                    <dt [ngClass]="this.lang.LangState=='en' ? ['text-lg leading-6 font-medium text-gray-900']:['text-lg leading-6 font-bold text-gray-900']">
                        {{'work.Backend_Development' |translate}}
                    </dt>
                    <dd [ngClass]="this.lang.LangState=='en' ? ['text-base text-gray-500']:['text-base my-1 text-gray-500']">
                        {{'work.Backend_Development_Intro' |translate}}
                        
                    </dd>
                </div>
            </div>

        </li>



        <li>
            <div class="flex my-2">
                <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-400 text-white">

                        <span class="text-xl font-bold">4</span>
                    </div>
                </div>
                <div class="ml-4">
                    <dt [ngClass]="this.lang.LangState=='en' ? ['text-lg leading-6 font-medium text-gray-900']:['text-lg leading-6 font-bold text-gray-900']">
                        {{'work.Go_Live' |translate}}
                    </dt>
                    <dd [ngClass]="this.lang.LangState=='en' ? ['text-base text-gray-500']:['text-base my-1 text-gray-500']">
                        {{'work.Go_Live_Intro' |translate}}
                        
                    </dd>
                </div>
            </div>

        </li>

    </ol>
</div>