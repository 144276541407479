<!-- This example requires Tailwind CSS v2.0+ -->
<div>
    <nav class="bg-gray-800">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between h-16">
                <div class="flex items-center">
                    <div class="flex-shrink-0 cursor-pointer" [routerLink]="['/']">
                        <img class="h-auto w-44" src="assets/logo-color-white.png" alt="Inhouse Orders Logo">
                    </div>



                    <div class="hidden md:block">
                        <div class="ml-10 flex items-baseline space-x-4">
                            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                            <!-- <a [routerLink]="['']" routerLinkActive="activeRoute"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                [routerLinkActiveOptions]="{exact: true}">{{'nav.Home' |translate}}</a> -->

                            <a [routerLink]="['/get-started']" routerLinkActive="router-link-active"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{{'nav.Get_Started'
                                |translate}}</a>

                            <a class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                [routerLink]="['/pricing']" routerLinkActive="router-link-active">{{'nav.Pricing'
                                |translate}}</a>

                            <a [routerLink]="['how-it-works']" routerLinkActive="activeRoute"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{{'nav.How_It_Works'
                                |translate}}</a>

                            <a [routerLink]="['/faq']" routerLinkActive="router-link-active"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium hidden lg:block">{{'nav.FAQ'
                                |translate}}</a>

                            <a href="https://dashboard.inhouseorders.io/"
                                class="inline-flex items-center justify-center px-5 py-2 text-sm font-medium rounded-full text-white bg-gradient-to-r from-green-400 to-blue-500 my-auto">
                                {{'nav.Dashboard' | translate}}
                                <svg class="w-4 h-4 mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd" />
                                </svg>
                            </a>


                        </div>
                    </div>

                </div>
                <div class="hidden md:block">
                    <div class="ml-4 flex items-center md:ml-6">


                        <!-- Profile dropdown -->
                        <div class="ml-3 relative text-gray-200">
                            <div class="dropdown">
                                <button (click)="accountMenuOpen = !accountMenuOpen"
                                    class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none f focus:ring-offset-gray-800 focus:ring-white"
                                    id="user-menu" aria-haspopup="true">
                                    <span class="sr-only">Open user menu</span>
                                    <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>

                                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 bg-white rounded w-32 border -ml-16" role="menu"
                                    aria-orientation="vertical" aria-labelledby="user-menu">
                                    <div class="py-1" role="menu" aria-orientation="vertical"
                                        aria-labelledby="options-menu">

                                        <li>
                                            <a [routerLink]="['/account']"
                                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                role="menuitem">
                                                {{'nav.Your_Account' | translate}}</a>
                                        </li>
                                        <li>
                                            <a (click)="afAuth.signOut()"
                                                class="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100"
                                                role="menuitem">{{'nav.Sign_out' | translate}}</a>
                                        </li>
                                    </div>


                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="-mr-2 flex md:hidden">
                    <!-- Mobile menu button -->
                    <button
                        class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        (click)="mobileMenuOpen = !mobileMenuOpen">
                        <span class="sr-only">Open main menu</span>
                        <!--
                Heroicon name: menu
  
                Menu open: "hidden", Menu closed: "block"
              -->
                        <svg class="h-6 w-6" [ngClass]="mobileMenuOpen ? 'hidden': 'block'"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <!--
                Heroicon name: x
  
                Menu open: "block", Menu closed: "hidden"
              -->
                        <svg class="h-6 w-6" [ngClass]="mobileMenuOpen ? 'block': 'hidden'"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!--
        Mobile menu, toggle classes based on menu state.
  
        Open: "block", closed: "hidden"
      -->
        <div class="md:hidden" [ngClass]="[mobileMenuOpen ? 'block': 'hidden' ]">
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <a [routerLink]="['']" routerLinkActive="router-link-active"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    [routerLinkActiveOptions]="{exact: true}">{{'nav.Home' |translate}}</a>

                <a [routerLink]="['/get-started']" routerLinkActive="router-link-active"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">{{'nav.Get_Started'
                    |translate}}</a>

                <a class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    [routerLink]="['/pricing']" routerLinkActive="router-link-active">{{'nav.Pricing' |translate}}</a>

                <a [routerLink]="['/how-it-works']" routerLinkActive="router-link-active"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">{{'nav.How_It_Works'
                    |translate}}</a>

                <a [routerLink]="['/faq']" routerLinkActive="router-link-active"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">{{'nav.FAQ'
                    |translate}}</a>


            </div>
            <div class="pt-4 pb-3 border-t border-gray-700">
                <!-- <div class="flex items-center px-5">
                    <div class="flex-shrink-0">
                        <img class="h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt="">
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium leading-none text-white">Tom Cook</div>
                        <div class="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
                    </div>
                </div> -->
                <div class="mt-3 px-2 space-y-1">
                    <a [routerLink]="['/account']" routerLinkActive="router-link-active"
                        class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">
                        {{'nav.Your_Account' | translate}}</a>

                    <a href="https://dashboard.inhouseorders.io/"
                        class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">
                        {{'nav.Restaurant_Dashboard' | translate}}</a>

                    <a (click)="afAuth.signOut()" *ngIf="(afAuth.user | async)"
                        class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">{{'nav.Sign_out'
                        | translate}}</a>
                </div>
            </div>
        </div>
    </nav>
    <main>
        <div class="max-w-7xl mx-auto">
            <!-- Replace with your content -->
            <div class="w-full flex justify-end flex-wrap ">
                <div class="m-2 mb-0 ml-auto dropdown">
                    <button type="button" (click)="this.lang.show=!this.lang.show"
                        class="inline-flex justify-center w-full rounded-md  shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 border-2 "
                        id="options-menu" aria-haspopup="true" aria-expanded="true">
                        {{this.lang.siteLanguage}}
                        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 -ml-4 rounded">
                        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                            <li (click)="this.lang.switchLanguage('en')" class="border w-32 text-center">
                                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem">English</a>
                            </li>

                            <li (click)="this.lang.switchLanguage('cn')" class="border w-32 text-center">
                                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem">中文</a>
                            </li>



                        </div>
                    </ul>
                </div>
            </div>


            <router-outlet></router-outlet>
            <!-- /End replace -->
        </div>
    </main>
</div>