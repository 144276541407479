import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { FaqComponent } from './faq/faq.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { PricingComponent } from './pricing/pricing.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent,
    data: {
      title: 'Inhouse Orders - Free Online Ordering for Restaurants',
      descrption: 'Restaurants learn how to accept online orders for free. Complete with Grubhub and Uber eats and save money. ',
      ogTitle: 'Inhouse Orders - Free Online Ordering for Restaurants',
      ogDescription: 'Restaurants learn how to accept online orders for free. Complete with Grubhub and Uber eats and save money. ',
      ogImage: 'https://res.cloudinary.com/inhouse-orders/image/upload/w_1200,ar_2:1,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg'
    
    }
  },
  {
    path: 'pricing', component: PricingComponent,
    data: {
      title: 'Pricing - Free Online Ordering for Restaurants',
      descrption: 'Learn how Inhouse Orders pricing can make online ordering free for your restaurant.',
      ogTitle: 'Pricing - Free Online Ordering for Restaurants',
      ogDescription: 'Learn how Inhouse Orders pricing can make online ordering free for your restaurant.',
      ogImage: 'https://res.cloudinary.com/inhouse-orders/image/upload/w_1200,ar_2:1,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg'

    }
  },
  {
    path: 'get-started', component: GetStartedComponent,
    data: {
      title: 'Get Started - Free Online Ordering for Restaurants',
      descrption: 'Learn how Inhouse Orders pricing can make online ordering free for your restaurant.',
      ogTitle: 'Pricing - Free Online Ordering for Restaurants',
      ogDescription: 'Learn how Inhouse Orders pricing can make online ordering free for your restaurant.',
      ogImage: 'https://res.cloudinary.com/inhouse-orders/image/upload/w_1200,ar_2:1,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg'

    },
  },
  {
    path: 'how-it-works', component: HowItWorksComponent,
    data: {
      title: 'How It Works - Free Online Ordering for Restaurants',
      descrption: 'This guide covers how to upload your menu and get your online ordering started.',
      ogTitle: 'How It Works - Free Online Ordering for Restaurants',
      ogDescription: 'This guide covers how to upload your menu and get your online ordering started.',
      ogImage: 'https://res.cloudinary.com/inhouse-orders/image/upload/w_1200,ar_2:1,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg'

    },
  },
  {
    path: 'faq', component: FaqComponent,
    data: {
      title: 'Frequently Asked Questions - Free Online Ordering for Restaurants',
      descrption: 'This list of our most frequently asked questions will help you learn more about the IO platorm. ',
      ogTitle: 'Frequently Asked Questions - Free Online Ordering for Restaurants',
      ogDescription: 'This list of our most frequently asked questions will help you learn more about the IO platorm. ',
      ogImage: 'https://res.cloudinary.com/inhouse-orders/image/upload/w_1200,ar_2:1,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg'

    },
  },
  {
    path: 'account', component: AccountComponent,
    data: {
      title: 'Account - Inhouse Orders',
      ogImage: 'https://res.cloudinary.com/inhouse-orders/image/upload/w_1200,ar_2:1,q_auto,f_auto/v1609732102/food%20pics/AdobeStock_140462047_spteun.jpg'

    },
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
