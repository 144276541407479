<div class="flex flex-wrap py-12 px-4 sm:px-6 lg:px-8">
    <h1 class="w-full text-4xl font-bold text-center my-4 mb-10"> {{'pricing.coming_back' |translate}}</h1>

    <div class="lg:w-full flex flex-wrap shadow-xlmt-8 rounded-lg shadow-lg bg-gray-100">
        <div class="w-full lg:w-3/4  p-6 ">
            <h2 class="text-2xl font-bold">{{'pricing.Free' |translate}}</h2>
            <p class="text-sm text-gray-500 mt-2">{{'pricing.subtotal_and_tax' |translate}}<b>{{'pricing.tips' |translate}}</b> {{'pricing.fee' |translate}}</p>
            <div class="flex align-middle mt-8">
                <p class="my-auto uppercase text-indigo-400 text-xs font-bold">{{'pricing.Included' |translate}}</p>
                <hr class="flex-1 my-auto mx-2 border border-solid rounded-sm">
            </div>
            <ul class="flex flex-wrap mt-8">

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                         {{'pricing.Online_Ordering' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Google' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Custom_Website' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Free_Tablet' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Support' |translate}}
                    </span>
                </li>
                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Menu' |translate}}
                    </span>
                </li>
            </ul>
            <!-- <div class="mt-2 p-2 border rounded-lg">
                <h3 class="text-lg font-bold">Add-ons:</h3>
                <ul>
                    <li class="w-full md:w-1/2">
                        <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="font-bold text-xs ml-2 text-gray-500">
                            Receipt Printer: $300
                        </span>
                    </li>
                </ul>
            </div> -->
        </div>
        <div class="w-full lg:w-1/4 flex flex-wrap justify-center p-4 bg-gray-200 rounded-r-lg">
            <p class="w-full text-center my-6">*{{'pricing.pay_nothing' |translate}} </p>
            <p class="text-5xl font-bold">5 </p><span
                class="text-gray-600 font-black text-xl uppercase pt-2 ml-1">%</span>
            <div class="w-full block">
                <a [routerLink]="['/get-started']" 
                    class="my-4 block w-full text-center py-2 text-base font-xs rounded-md text-white bg-gradient-to-r from-green-400 to-blue-500">
                    {{'pricing.Get_started' |translate}}
                </a>
            </div>
            <a [routerLink]="['/get-started']" class="text-base text-gray-700 underline">{{'pricing.Demo' |translate}}</a>
        </div>
    </div>

<!-- 

    <div class="lg:w-full flex flex-wrap shadow-xlmt-8 rounded-lg shadow-lg bg-gray-100 mt-4">
        <div class="w-full lg:w-3/4  p-6 ">
            <h2 class="text-2xl font-bold"> {{'pricing.Monthly_Plan' |translate}}</h2>
            <p class="text-sm text-gray-500 mt-2"> {{'pricing.Monthly_Plan_Intro' |translate}}</p>
            <div class="flex align-middle mt-8">
                <p class="my-auto uppercase text-indigo-400 text-xs font-bold">{{'pricing.Included' |translate}}</p>
                <hr class="flex-1 my-auto mx-2 border border-solid rounded-sm">
            </div>
            <ul class="flex flex-wrap mt-8">

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Online_Ordering' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-yellow-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Google' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-yellow-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Custom_Website' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-yellow-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Free_Tablet' |translate}}
                    </span>
                </li>

                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                       {{'pricing.Support' |translate}}
                    </span>
                </li>
                <li class="w-full md:w-1/2">
                    <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-xs ml-2 text-gray-500">
                        {{'pricing.Menu' |translate}}
                    </span>
                </li>
            </ul>

            <div class="mt-2 p-2 border rounded-lg">
                <h3 class="text-lg font-bold">{{'pricing.Add_ons' |translate}}:</h3>
                <ul>
                    <li class="w-full md:w-1/2">
                        <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="font-bold text-xs ml-2 text-gray-500">
                             {{'pricing.Website_price' |translate}}
                        </span>
                    </li>
                    <li class="w-full md:w-1/2">
                        <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="font-bold text-xs ml-2 text-gray-500">
                            {{'pricing.Google_price' |translate}}
                        </span>
                    </li>
                    <li class="w-full md:w-1/2">
                        <svg class="h-5 w-5 text-green-500 inline-block" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="font-bold text-xs ml-2 text-gray-500">
                             {{'pricing.Printer_price' |translate}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-full lg:w-1/4 flex flex-wrap justify-center p-4 bg-gray-200 rounded-r-lg">
            <p class="w-full text-center my-6">{{'pricing.Great' |translate}}</p>
            <p class="text-4xl font-bold">$250 </p><span class="text-gray-600 text-xl pt-2 ml-1">/mo</span>
            <div class="w-full block my-4">
                <a [routerLink]="['/get-started']" 
                    class="block w-full text-center py-2 text-base font-xs rounded-md text-white bg-gradient-to-r from-pink-400 to-purple-500">
                    {{'pricing.Get_started' |translate}}
                </a>
            </div>
            <p [routerLink]="['/get-started']" routerLinkActive="router-link-active"
                class="text-base text-gray-700 underline">{{'pricing.Demo' |translate}}</p>
        </div>
    </div>
 -->




</div>