<div class="w-full flex flex-wrap bg-gray-100 h-screen" *ngIf="(afAuth.user | async) as user else login">

    <div class="w-full md:w-1/4 bg-white rounded p-3 shadow-lg">
        <div class="flex items-center space-x-4 p-2 mb-5">
            <!-- <img class="h-12 rounded-full"
                src="http://www.gravatar.com/avatar/2acfb745ecf9d4dccb3364752d17f65f?s=260&d=mp" alt="James Bhatta"> -->
            <div>
                <h4 class="text-sm tracking-wide flex items-center space-x-1">{{'account.Signed' |translate}}:</h4>
                <span class="text-sm tracking-wide flex items-center space-x-1">
                    <p class="text-gray-600">{{user.email}}</p>
                </span>
            </div>
        </div>
        <ul class="space-y-2 text-sm">
            <li>
                <a (click)="selectedSubpage = 'formsubs'"
                    class="flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline bg-gray-200">
                    <span class="text-gray-600 ">
                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z">
                            </path>
                        </svg>
                    </span>
                    <span> {{'account.Form_Submissions' | translate}}</span>
                </a>
            </li>

        </ul>
    </div>

    <div class="w-full md:w-3/4">
        <div [ngSwitch]="selectedSubpage" class="p-4 text-gray-500 w-full">

            <div *ngSwitchCase="'formsubs'">
                <app-form-submission *ngFor="let form of forms | async" [form]="form">

                </app-form-submission>

            </div>
            <div *ngSwitchDefault>{{'account.output2' |translate}}</div>

        </div>



    </div>
</div>
<ng-template #login>
        <app-login></app-login>

</ng-template>