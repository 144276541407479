<div class="md:grid md:grid-cols-3 md:gap-6 py-12 px-4 sm:px-6 lg:px-8">
    <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">{{'start.Contact_Information' |translate}}</h3>
            <p class="mt-1 text-sm text-gray-600">
                {{'start.intro' |translate}}                
            </p>
        </div>
    </div>
    <div class="mt-5 md:mt-0 md:col-span-2">
        <form [formGroup]="getStartedForm" *ngIf="!submitted">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div class="col-span-6 sm:col-span-3">

                        <label for="name" class="block text-sm font-medium text-gray-700">{{'start.Contact_name' |translate}}</label>
                        <input type="text" name="name" autocomplete="given-name" formControlName="name"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder="Jane Lee">

                    </div>
                    <div class="col-span-6 sm:col-span-3">

                        <label for="phone" class="block text-sm font-medium text-gray-700">{{'start.Contact_phone' |translate}}
                            </label>
                        <input type="tel" name="phone" autocomplete="tel"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            mask="(000) 000-0000" placeholder="(303) 888-8888" formControlName="phone">

                    </div>
                    <div class="col-span-6 sm:col-span-3">

                        <label for="email" class="block text-sm font-medium text-gray-700">{{'start.Contact_email' |translate}}</label>
                        <input type="email" name="email" autocomplete="email"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder="myemail@example.com" formControlName="email">

                    </div>
                    <div class="col-span-6">

                        <label for="restaurant_name" class="block text-sm font-medium text-gray-700">{{'start.Restaurant_name' |translate}}
                            </label>
                        <input type="text" name="restaurant_name" autocomplete="organization"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder="Hot Thai Restaurant" formControlName="restaurant_name">

                    </div>
                    <div class="col-span-6">

                        <label for="restaurant_address" class="block text-sm font-medium text-gray-700">{{'start.Restaurant_address' |translate}}
                           </label>
                        <input type="text" name="restaurant_address" autocomplete="none"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder="123 Main St Denver, CO" formControlName="restaurant_address">

                    </div>

                </div>
                <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button (click)="submitForm()"
                        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">

                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24" *ngIf="loading">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                        {{'start.Submit' |translate}}
                    </button>
                </div>
            </div>
        </form>
        <div *ngIf="submitted"
            class="w-full rounded-xl flex text-center align-middle bg-gray-100 p-4 flex-wrap justify-center">
            <span class="text-green-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    class="w-24 h-24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </span>

            <div class="py-5">
                <div class="border-t border-gray-600"></div>
            </div>

            <span class="text-xl font-bold my-auto">
                {{'start.sucesfully' |translate}} We will contact you as soon as possible. 
            </span>
            <p class="w-full">{{'start.now_or_later' |translate}} </p>
            <app-login></app-login>
        </div>


    </div>
</div>



<div class="hidden sm:block" aria-hidden="true">
    <div class="py-5">
        <div class="border-t border-gray-200"></div>
    </div>
</div>


<!-- <pre *ngIf="(afAuth.user | async) as user">{{user | json}}</pre> -->