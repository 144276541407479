<div class="flex flex-wrap items-center mt-8 h-full w-full justify-center sm:w-3/4 md:w-1/2 lg:w-1/3 m-auto px-4"
    *ngIf="!(afAuth.user | async) else loggedIn" style="min-width: 300px;">
    <div *ngIf="!loginWithEmail" class="w-full">

        <div class="flex flex-wrap justify-center">
            <img class="mx-auto h-24 w-auto" src="../../assets/favicon.png" alt="Workflow">
            <h2 class="my-6 text-center text-3xl font-extrabold text-gray-900">
                Sign in to your account
            </h2>
        </div>
        <button class="w-full shadow-sm rounded-md bg-white border-gray-200 mx-auto mb-2 block border-2 p-2" id="google"
            (click)="googleSignin()">
            Login With Google
        </button>
        <button class="shadow-sm rounded-md text-white bg-blue-700 mx-auto mb-2 block border-2 p-2" id="fb"
            (click)="fbSignin()">
            Login With Facebook
        </button>
        <button id="guest" (click)="loginWithEmail = true"
            class="w-full shadow-sm rounded-md text-white bg-red-600 mx-auto mb-2 block p-2">
            Login with Email
        </button>
        <p class="text-xs text-gray-600 text-center">By continuing, you are indicating that you accept our Terms of
            Service
            and Privacy Policy.</p>
    </div>

    <div *ngIf="loginWithEmail" class="rounded-md flex items-center justify-center bg-gray-50">
        <div class="max-w-md w-full space-y-8 m-6">
            <div>
                <img class="mx-auto h-24 w-auto" src="../../assets/favicon.png" alt="Workflow">
                <h2 class="my-6 text-center text-3xl font-extrabold text-gray-900">
                    Sign in to your account`
                </h2>
            </div>
            <!-- <form class="mt-8 space-y-6" action="#" method="POST"> -->
            <input type="hidden" name="remember" value="true">
            <div class="rounded-md shadow-sm -space-y-px">
                <div>
                    <label for="emailInput" class="sr-only">Email address</label>
                    <input id="emailInput" name="emailInput" type="email" autocomplete="email" required
                        class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Email address" [(ngModel)]="emailInput">
                </div>
                <div>
                    <label for="pwInput" class="sr-only">Password</label>
                    <input id="pwInput" name="pwInput" type="password" autocomplete="current-password" required
                        class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Password" [(ngModel)]="pwInput">
                </div>
            </div>

            <div class="flex flex-wrap items-center justify-between">

                <div class="w-full block text-sm">
                    <div (click)="forgotPassword = !forgotPassword"
                        class="cursor-pointer w-full font-medium text-indigo-600 hover:text-indigo-500">
                        Forgot your password?
                    </div>
                </div>
                <div *ngIf="forgotPassword" class="w-full rounded-md shadow-sm">
                    <div>
                        <label for="emailInput" class="sr-only">Email address</label>
                        <input id="emailInput" name="emailInput" type="email" autocomplete="email" required
                            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Email address" [(ngModel)]="emailInput">
                    </div>
                    <button
                        class="m-2 shadow-sm group relative py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        (click)="forgotPassword = !forgotPassword">
                        Cancel
                    </button>
                    <button
                        class="flex-grow my-2 shadow-sm group relative py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        (click)="login()">
                        Send Reset Email
                    </button>
                </div>
            </div>

            <div class="flex justify-end">
                <button
                    class="mx-1 shadow-sm group relative py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    (click)="signup()">
                    Sign Up
                </button>

                <button
                    class="mx-1 shadow-sm group relative py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    (click)="login()">

                    Login
                </button>

            </div>
            <!-- </form> -->
        </div>
    </div>







</div>

<ng-template #loggedIn>
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        You are logged in.
    </h2>
</ng-template>