import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  siteLanguage='English';
  LangState="en"
  show=false;

  constructor(public translate: TranslateService) {
    translate.setDefaultLang('en');
    this.translate.use('en');
    // console.log('lang: ',this.translate.currentLang)
   }

   switchLanguage(language: string) {
    this.LangState=language;
    if (language=='en') {
      this.siteLanguage='English';
      this.show=false;
    } else {
      this.siteLanguage='中文';
      this.show=false;
    }
    this.translate.use(language);
    // console.log('lang: ',this.translate.currentLang)
  }
}
