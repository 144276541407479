import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
// import * as firebase from 'firebase/app';
import { Router } from '@angular/router';


@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {

  public getStartedForm: FormGroup;
  public loading = false;
  public submitted = false;
  public formSubmissionPath: string;

  constructor(private router: Router, private cloudFunctions: AngularFireFunctions, public afAuth: AngularFireAuth, fb: FormBuilder, private afs: AngularFirestore) {
    this.getStartedForm = fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      restaurant_name: ['', Validators.required],
      restaurant_address: ['', Validators.required],
    })
  }

  ngOnInit(): void {
  }

  async submitForm() {
    this.loading = true;

    if (this.getStartedForm.valid) {
      const callable = this.cloudFunctions.httpsCallable('submit_onboarding_form');
      this.formSubmissionPath = await callable({ ...this.getStartedForm.value }).toPromise();
      console.log('path', this.formSubmissionPath)
      this.afAuth.user.subscribe(user => {
        if (user && this.formSubmissionPath) {
          this.afs.doc(this.formSubmissionPath).set({ 'user': user.uid }, { merge: true })

        }
      })
    } else {

      return; // do nothing if form is invalid

    }
    this.loading = false;
    this.submitted = true;
    

  }


}
