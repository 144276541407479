import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  public inhouse_user: any;
  public user$;
  public user;
  public selectedSubpage = 'formsubs';

  private formsCollection: AngularFirestoreCollection<any>;
  forms: Observable<any[]>;

  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore) { }

  ngOnInit(): void {
    // afs.collection('items', ref => ref.where('size', '==', 'large'))


    this.afAuth.user.subscribe(user => {
      if (user) {
        this.afs.doc('inhouse_users/' + user.email).get().toPromise().then(doc => {
          this.inhouse_user = doc.data()
          this.formsCollection = this.afs.collection<any>('onboarding_forms', ref => ref.where('user', '==', user.uid));
          this.forms = this.formsCollection.valueChanges();
        })
      }


    })




  }

  ngOnDestroy() {

  }

}
